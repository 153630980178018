import React from "react";
import Head from "next/head";
import BasicHeader from "@components/BasicHeader";
import BasicFooter from "@components/BasicFooter";
import Layout from "@components/Layout";

export default function Custom404() {
  return (
    <Layout title="Snacktime - 404">
      <Head>
        <meta
          property="og:url"
          content={process.env.NEXT_PUBLIC_SNACKTIME_PUBLIC_WEB_URL}
          key="ogurl"
        />
        <meta property="og:site_name" content="Snacktime" key="ogsitename" />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_SNACKTIME_PUBLIC_WEB_URL}/Snacktime-app-icon-1024x1024.png`}
          key="ogimage"
        />
        <meta property="og:title" content="Snacktime" key="ogsitename" />
        <meta property="og:type" content="website" key="ogtype" />
        <meta
          property="og:description"
          content="Photos, videos, and luck"
          key="ogdesc"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:site"
          content={process.env.NEXT_PUBLIC_SNACKTIME_TWITTER_USERNAME}
        />
        <meta name="twitter:title" content="Snacktime" />
        <meta name="twitter:description" content="Photos, videos, and luck" />
        <meta
          name="twitter:image"
          content={`${process.env.NEXT_PUBLIC_SNACKTIME_PUBLIC_WEB_URL}/Snacktime-app-icon-1024x1024.png`}
        />
        <meta
          property="twitter:image:alt"
          content="Snacktime - photos, videos, and luck"
        />
        <meta
          property="fb:app_id"
          content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
        />
      </Head>
      <div className="flex justify-between flex-col items-center h-screen max-h-screen">
        <BasicHeader />
        <h1 className="text-3xl">404 - Page Not Found</h1>
        <BasicFooter />
      </div>
    </Layout>
  );
}
