import React from "react";
import Link from "next/link";

export default function BasicHeader() {
  return (
    <div className="container pt-2 md:pt-4 px-6 mx-auto flex flex-wrap flex-col md:flex-row items-center">
      <div className="flex-1 flex-col justify-start lg:items-start overflow-y-hidden">
        <Link href="/">
          <img
            src="/Snacktime-logo.png"
            width="315"
            height="75"
            alt="Snacktime"
          />
        </Link>
      </div>
    </div>
  );
}
