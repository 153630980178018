import React from "react";
import Link from "next/link";

export default function BasicFooter() {
  return (
    <div className="w-full container mx-auto p-2">
      <div className="w-full flex items-center justify-between">
        <div className="flex mx-auto md:mx-2 items-center flex-wrap flex-row justify-items-center justify-evenly md:justify-start">
          <div>
            <p className="no-underline hover:underline">
              <Link href="/terms">Terms of Service</Link>
            </p>
          </div>
          <div className="pl-5">
            <p className="no-underline hover:underline">
              <Link href="/privacy">Privacy</Link>
            </p>
          </div>
          <div className="pl-5">
            <p className="no-underline hover:underline">
              <a href={`mailto:${process.env.NEXT_PUBLIC_SNACKTIME_EMAIL}`}>
                Contact
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="flex mx-auto md:mx-2 items-center flex-wrap flex-row justify-items-center justify-evenly md:justify-start">
        <div>
          <p className="text-xs">&copy; Snacktime {new Date().getFullYear()}</p>
        </div>
        <div className="pl-2">
          <p className="text-xs no-underline hover:underline">
            <a
              href="https://www.convective.com"
              target="_blank"
              rel="noreferrer"
            >
              Built with ❤️ in Florida
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
